import { isEqual, isValid, max, min } from 'date-fns';
import routes from '../config/routes';
import {
  AppPermissions,
  Closing,
  ClosingDashboardViewMode,
  ClosingDetailGroup,
  ClosingViewMode,
  Country,
  DateValue,
  GroupBy,
  MastersImport,
  Permission,
  RemoteResource,
  ReportFilter,
  PendingInterfaces,
  PendinInterfacesViewMode,
} from '../types';
import constants from './constants';

/**
 * Determina si algún permiso de `userPermissions` existe en `permissions`
 * @param userPermissions Permisos que tiene asignado el usuario
 * @param permissions Permisos que se desean comparar
 * @returns true si algún elemento de `userPermissions` existe en `permissions` o si `userPermissions`
 * tiene elementos pero `permissions` no. Retorna false en caso de que `userPermissions` no tenga
 * elementos o si ningún elemento de `userPermissions` existe en `permissions`.
 *
 */
export const hasPermissions = (
  userPermissions: string[],
  permissions: AppPermissions | AppPermissions[]
) => {
  if (!userPermissions.length) return false;
  if (Array.isArray(permissions))
    return !permissions.length || userPermissions.some(k => permissions.some(j => j === k));
  return userPermissions.includes(permissions);
};

export const getRoutePathDefault = (userPermissions: string[]) => {
  const { protectedRoutes, protectedNestedRoutes } = routes;

  const route =
    Object.values(protectedRoutes).find(
      i =>
        i.defaultRedirectForPermission && userPermissions.includes(i.defaultRedirectForPermission)
    ) ||
    Object.values(protectedNestedRoutes).find(
      i =>
        i.index.defaultRedirectForPermission &&
        userPermissions.includes(i.index.defaultRedirectForPermission)
    )?.index;

  return route?.path;
};

export const nameToInitials = (fullName: string) => {
  const parts = fullName.trim().split(' ');
  return parts.length === 1
    ? `${parts[0].charAt(0)}`
    : `${parts[0].charAt(0)}${parts.at(-1)?.charAt(0)}`;
};

export const undefinedIfEmpty = (value: string | undefined) => (value === '' ? undefined : value);

export const getDateValue = (value: object | null | undefined): DateValue => ({
  raw: value,
  accept: value && isValid(value) ? (value as Date) : null,
});

export const isEqualNullableDate = (
  date1: Date | null | undefined,
  date2: Date | null | undefined
) => (date1 && date2 && isEqual(date1, date2)) || (!date1 && !date2);

export const generateRowId = <T>(data: T[]): T[] =>
  data.map((item, index) => ({ ...item, rowId: index }));

export const onlyUnique = <T>(data: T[]): T[] => [...new Set(data)];

export const handleLoadByViewMode = (
  viewMode: ClosingViewMode,
  sales: RemoteResource<Closing[]>,
  collections: RemoteResource<Closing[]>
) => {
  if (viewMode === ClosingViewMode.SALES) {
    const { loading, loaded } = sales;
    return [loading, loaded];
  }
  if (viewMode === ClosingViewMode.COLLECTIONS) {
    const { loading, loaded } = collections;
    return [loading, loaded];
  }
  if (viewMode === ClosingViewMode.CONSOLIDATED) {
    const loading = sales.loading || collections.loading;
    const loaded = sales.loaded && collections.loaded;
    return [loading, loaded];
  }
  const loading = false;
  const loaded = false;
  return [loading, loaded];
};

export const handleLoadByViewModePendingInterfaces = (
  viewMode: PendinInterfacesViewMode,
  sales: RemoteResource<PendingInterfaces[]>,
  collections: RemoteResource<PendingInterfaces[]>
) => {
  if (viewMode === PendinInterfacesViewMode.SALES) {
    const { loading, loaded } = sales;
    return [loading, loaded];
  }
  if (viewMode === PendinInterfacesViewMode.COLLECTIONS) {
    const { loading, loaded } = collections;
    return [loading, loaded];
  }
  const loading = false;
  const loaded = false;
  return [loading, loaded];
};

export const getStatusColor = (status: string) => {
  if (status.includes('OK')) return '#60bb44';
  if (status.includes('ERROR')) return '#ca2222';
  return '#b9afaf';
};

export const handleErrorPercent = (itemMode: 'error' | 'ok', percent: number) => {
  if (itemMode === 'ok') {
    if (percent === 100) {
      return '#60bb44';
    }
    if (percent > 90 && percent < 100) {
      return '#f8d330';
    }
    return '#ca2222';
  }
  if (percent === 0) {
    return '#60bb44';
  }
  if (percent > 0 && percent < 10) {
    return '#f8d330';
  }
  return '#ca2222';
};

export const groupBy = <TValue>(
  options: readonly TValue[],
  propGetter: (group: TValue) => string
): GroupBy<TValue> =>
  options.reduce((prev, curr) => {
    const group = propGetter(curr);
    if (!(group in prev)) prev[group] = [];
    prev[group].push(curr);
    return prev;
  }, {} as GroupBy<TValue>);

export const getCountriesForPermissions = (
  requiredPermissions: AppPermissions[],
  userPermissions: Permission[],
  countries: Country[] | undefined
) => {
  const userPermissionForResource = userPermissions.find(i =>
    requiredPermissions.includes(i.permission as AppPermissions)
  );
  if (userPermissionForResource) {
    const { countries: permissionCountries } = userPermissionForResource;
    if (permissionCountries.find(i => i.countryAcronym === 'FULL')) return countries || [];
    return (
      countries && countries.length > 0
        ? permissionCountries.map(p => countries?.find(c => c.countryCode === p.countryAcronym))
        : []
    ) as Country[];
  }

  return [] as Country[];
};

// Si la fecha endDate es válida y existe una startDate valida (accept), se asigna en startDate
// la más antigua entre ellas. Esto es para que nunca endDate sea más antigua que startDate.
export const getValidStartDate = (startDate: DateValue, endDate: Date | undefined | null) =>
  startDate.accept && endDate && isValid(endDate)
    ? min([endDate, startDate.accept])
    : startDate.accept;

// Si la nueva fecha startDate es válida y existe una endDate valida (accept), se asigna en endDate
// la más reciente  entre ellas. Esto es para que nunca startDate sea más reciente que endDate.
export const getValidEndDate = (startDate: Date | undefined | null, endDate: DateValue) =>
  endDate.accept && startDate && isValid(startDate)
    ? max([startDate, endDate.accept])
    : endDate.accept;

export const getSelectedStartDate = (filters: ReportFilter) =>
  filters.startAccountingDate.accept ||
  filters.startBusinessDate.accept ||
  filters.startOperationDate.accept;

export const getSelectedEndDate = (filters: ReportFilter) =>
  filters.endAccountingDate.accept ||
  filters.endBusinessDate.accept ||
  filters.endOperationDate.accept;

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function => value instanceof Function;

export const masterSort = (a: MastersImport, b: MastersImport): number => {
  if (a.state < b.state) return -1;
  if (a.state > b.state) return 1;

  if (a.importDate && b.importDate) {
    if (a.importDate > b.importDate) return -1;
    if (a.importDate < b.importDate) return 1;
  }

  if (a.type < b.type) return -1;
  if (a.type > b.type) return 1;

  if (a.country < b.country) return -1;
  if (a.country > b.country) return 1;

  return 0;
};

export const closingSort = (a: ClosingDetailGroup, b: ClosingDetailGroup): number => {
  if (a.country < b.country) return -1;
  if (a.country > b.country) return 1;

  if (a.federativeEntity && b.federativeEntity) {
    if (a.federativeEntity < b.federativeEntity) return -1;
    if (a.federativeEntity > b.federativeEntity) return 1;
  }

  if (a.franchiseType && b.franchiseType) {
    if (a.franchiseType < b.franchiseType) return -1;
    if (a.franchiseType > b.franchiseType) return 1;
  }

  if (a.store < b.store) return -1;
  if (a.store > b.store) return 1;

  if (a.cause < b.cause) return -1;
  if (a.cause > b.cause) return 1;

  return 0;
};

export const handleClosingDashboardViewMode = (viewMode: ClosingDashboardViewMode) => {
  let from = constants.dashboardIncorporationDates.yesterday;
  let to = constants.dashboardIncorporationDates.yesterday;
  if (viewMode === ClosingDashboardViewMode.today) {
    from = constants.dashboardIncorporationDates.today;
    to = constants.dashboardIncorporationDates.today;
  } else if (viewMode === ClosingDashboardViewMode.currentMonth) {
    from = constants.dashboardIncorporationDates.currentMonthFrom;
    to = constants.dashboardIncorporationDates.yesterday;
  } else if (viewMode === ClosingDashboardViewMode.lastMonth) {
    from = constants.dashboardIncorporationDates.lastMonthFrom;
    to = constants.dashboardIncorporationDates.lastMonthTo;
  }
  return { from, to };
};

export const isIncompleteDateRange = (startDate: DateValue, endDate: DateValue) =>
  Boolean(startDate.accept) !== Boolean(endDate.accept);

export const noValidDateRanges = (filters: ReportFilter) =>
  !(filters.startBusinessDate.accept && filters.endBusinessDate.accept) &&
  !(filters.startOperationDate.accept && filters.endOperationDate.accept) &&
  !(filters.startAccountingDate.accept && filters.endAccountingDate.accept);

export const hasIncompleteRanges = (filters: ReportFilter) =>
  isIncompleteDateRange(filters.startBusinessDate, filters.endBusinessDate) ||
  isIncompleteDateRange(filters.startOperationDate, filters.endOperationDate) ||
  isIncompleteDateRange(filters.startAccountingDate, filters.endAccountingDate);
